.readMore {padding: 46px 0;margin-top: 80px;}
.readMore p {max-height: 80px;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;}
.readMore .link {cursor: pointer;color: #eb5976;position: relative;top: 5px;}
.lowerContnt {max-height: 70px;overflow: hidden;}
.lowerContnt.fullHeight {max-height: 100%;}
.lowerContnt h1,.lowerContnt h2,.lowerContnt h3{margin-bottom: 15px;display: block;font-size: 16px;font-weight: 600;color: #222;}
.lowerContnt b {margin-bottom: 15px;display: block;font-size: 16px;font-weight: 600;color: #222;}
.lowerContnt label {margin-bottom: 15px;display: block;font-size: 16px;font-weight: 600;color: #222;}
/* .lowerContnt p {margin-bottom: 15px;display: block;font-size: 14px;} */
.lowerContnt p {margin-bottom: 15px;display: block;font-size: 16px;}
.lowerContnt ul {margin-top: 10px;padding-left: 14px;padding-bottom: 10px;}
.lowerContnt ul li {margin-bottom: 5px;font-size: 16px;}
.lowerContnt .faqsReadmore {margin-bottom: 15px;font-size: 15px;font-weight: 600;color: #222;}
